import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import * as styles from './article.module.css';
import Aside from './Aside/aside';

const ArticleList = ({ data, pageContext }) => {
  const edges = data.nanoCms.articles.edges;
  edges.map((edge) => edge.node);
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const location = 'ja';
  const { currentPage, numPages } = pageContext;
  const prevPageLink =
    currentPage - 1 === 1 ? '/' : `/pages/${currentPage - 1}`;
  const nextPageLink = `/pages/${currentPage + 1}`;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;

  return (
    <Layout location={location} title={siteTitle}>
      <main className={styles.articleMain}>
        <div className={styles.cntWrapper}>
          <Breadcrumb
            crumbs={[
              { pathname: '/', crumbLabel: 'TOP' },
              { pathname: '/pages', crumbLabel: 'コラム' },
            ]}
          />
          <div className={styles.cntWrapperIndex}>
            <div className={styles.mainnav}>
              <section>
                {data.nanoCms.articles.edges.map(({ node }) => (
                  <article className={styles.articleItem}>
                    <div className={styles.articleLeft}>
                      <Link to={`/${node.url}/`}>
                        {node.ogpImage ? (
                          <img src={node.ogpImage} alt="ogp" />
                        ) : (
                          <StaticImage
                            src="../../images/nanocms.png"
                            alt="topの画像"
                          />
                        )}
                      </Link>
                    </div>
                    <div className={styles.articleRight}>
                      <Link to={`/articles/${node.url}/`}>
                        <h2>{node.title}</h2>
                      </Link>
                      <p className={styles.articleRightSubtitle}>
                        {node.description}
                      </p>
                      <div className={styles.articleRightDate}>
                        <span>
                          <time>{node.updatedAt}</time>
                        </span>
                      </div>
                    </div>
                  </article>
                ))}
              </section>
              {/* Pagination Links */}
              <div className={styles.paginationBox}>
                {numPages > 3 && (
                  <Link to="/pages" className={styles.paginationBtnSide}>
                    最初
                  </Link>
                )}
                {!isFirst && (
                  <Link to={prevPageLink} className={styles.paginationBtnSide}>
                    前へ
                  </Link>
                )}

                {Array.from({ length: numPages }, (_, i) => i + 1)
                  .filter(
                    (pageNumber) => Math.abs(pageNumber - currentPage) <= 1,
                  )
                  .map((pageNumber) => (
                    <Link
                      key={`pagination-number${pageNumber}`}
                      to={pageNumber === 1 ? '/pages' : `/pages/${pageNumber}/`}
                      className={
                        pageNumber === currentPage
                          ? styles.paginationBtnActive
                          : styles.paginationBtn
                      }
                    >
                      {pageNumber}
                    </Link>
                  ))}

                {!isLast && (
                  <Link to={nextPageLink} className={styles.paginationBtnSide}>
                    次へ
                  </Link>
                )}
                {!isLast && numPages > 3 && (
                  <Link
                    to={`/pages/${numPages}/`}
                    className={styles.paginationBtnSide}
                  >
                    最後
                  </Link>
                )}
              </div>
            </div>
            <Aside />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export const Head = ({ data }) => {
  return <Seo description="記事一覧のページです" />;
};

export default ArticleList;

export const query = graphql`
  query ($limit: Int, $startKey: Int) {
    nanoCms {
      articles(isPublished: true, limit: $limit, startKey: $startKey) {
        edges {
          node {
            title
            url
            description
            ogpImage
            updatedAt
            timestamp
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
